import Vue from 'vue'
import Vuex from 'vuex'
import { elearningStore } from './elearningStore'
import { INITIALIZE_STORE, LOGIN_USER, LOGOUT_USER, SAVE_SEARCH_RESULTS, REFRESH_ACCESS_TOKEN } from "./mutation-types"
import jwt_decode from "jwt-decode";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: "",
    userInitials: "",
    profilePicture: "",
    accessToken: "",
    deviceToken: "",
    roles: [],
    // profilePicture: "",
    // universityName: "CLARK INTERNATIONAL UNIVERSITY",
    // logoImageUrl: require("../assets/img/clarke.png"),
    // backgroundImage: require("../assets/img/CIU-Cultural-diversity.jpg")

    universityName: "VICTORIA UNIVERSITY",
    logoImageUrl: require("../assets/img/vu-logo-with-words.png"),
    backgroundImage: require("../assets/img/cover_photo.jpg"),
    search_results: [],

  },
  mutations: {
    [REFRESH_ACCESS_TOKEN]: (state, { accessToken }) => {
      //Save The Access Token in local Storage 
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("l-at", accessToken);

      try {
        //@ts-ignore
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        state.accessToken = accessToken;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore
        state.roles = decoded.roles;
      } catch (error) {
        //Nothing to do
      }
    },
    [LOGIN_USER]: (state, { accessToken, fbDeviceToken }) => {
      //Save the Device Token as we need it for Single User Sessions
      state.deviceToken = fbDeviceToken;

      try {
        //@ts-ignore
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        state.accessToken = accessToken;
        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore
        state.roles = decoded.roles;
      } catch (error) {
        //Nothing to do
      }

      //Save The Access Token in local Storage 
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("l-at", accessToken);
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("l-dt", fbDeviceToken);
    },
    [LOGOUT_USER]: (state) => {
      //Save The Access Token in local Storage
      state.userId = "";
      state.accessToken = '';
      state.userInitials = '';
      state.profilePicture = ''
      state.deviceToken = "",
        state.search_results = [];
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).removeItem("l-at");
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).removeItem("l-dt");

    },
    [INITIALIZE_STORE]: (state) => {

      //Retrieve Device Token
      //@ts-ignore
      state.deviceToken = (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).getItem("l-dt");

      //Attempt to retrieve Token from local Storage
      let accessToken = (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).getItem("l-at");
      //If we have no access token, No use to proceed
      if (!accessToken) return;
      try {

        let decoded = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        state.accessToken = accessToken;

        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.profilePicture = decoded.pp;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore
        state.roles = decoded.roles;
      } catch (error) {
        //Nothing to do
        //TODO: HANDLE THIS SCENARIO
      }
    },
    [SAVE_SEARCH_RESULTS]: (state, payload) => {
      state.search_results = payload;
    }
  },
  getters: {
    isLoggedIn: state => {
      return !!state.userId;
    },

    isNonTeachingStaff: state => {
      //@ts-ignore
      return state.roles.includes("NON_TEACHING_STAFF");
    }

    // deviceToken: state => state.accessToken
  },
  plugins: [elearningStore],
  actions: {
  },
  modules: {
  }
})