import { render, staticRenderFns } from "./course-work-details.vue?vue&type=template&id=21e12ca1&scoped=true&"
import script from "./course-work-details.vue?vue&type=script&lang=js&"
export * from "./course-work-details.vue?vue&type=script&lang=js&"
import style0 from "./course-work-details.vue?vue&type=style&index=0&id=21e12ca1&prod&scoped=true&lang=css&"
import style1 from "./course-work-details.vue?vue&type=style&index=1&id=21e12ca1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e12ca1",
  null
  
)

export default component.exports