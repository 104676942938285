var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{attrs:{"id":"sidebar-article"}},[_c('div',{staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"margin-bottom":"10px","margin-top":"35px","display":"flex","align-items":"center","justify-content":"center"}},[_c('router-link',{attrs:{"to":"/dashboard/home"}},[_c('img',{staticClass:"mt-3 img-fluid",staticStyle:{"height":"65px"},attrs:{"src":_vm.$store.state.logoImageUrl,"alt":""}})])],1)]),_c('div',{staticClass:"theLists"},[_c('ul',{},[_c('li',{on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/home"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'Home' }},[_c('span',[_c('i',{staticClass:"fas fa-home"}),_vm._v(" Home")])])])],1),(!_vm.$store.getters.isNonTeachingStaff)?_c('li',{on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/my-content"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'MyContent' }},[_c('span',[_c('i',{staticClass:"fas fa-video"}),_vm._v(" My Content")])])])],1):_vm._e(),(!_vm.$store.getters.isNonTeachingStaff)?_c('li',{on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/my-batches"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'MyBatches' }},[_c('span',[_c('i',{staticClass:"fas fa-window-restore"}),_vm._v(" My Intakes")])])])],1):_vm._e(),(!_vm.$store.getters.isNonTeachingStaff)?_c('li',{class:_vm.$store.getters.canExamCRUD ? 'show_list' : 'hide_list',on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/course-work-sets"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'CourseWorkSets' ||
                  _vm.$route.name == 'EditCourseWorkSet',
              }},[_c('span',[_c('i',{staticClass:"fas fa-copy"}),_vm._v(" Course Work")])])])],1):_vm._e(),(!_vm.$store.getters.isNonTeachingStaff)?_c('li',{on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/lectures"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'Lectures' ||
                  _vm.$route.name == 'AllLectures' ||
                  _vm.$route.name == 'LiveLecture',
              }},[_c('span',[_c('i',{staticClass:"fas fa-video"}),_vm._v(" Lectures")])])])],1):_vm._e(),(!_vm.$store.getters.isNonTeachingStaff)?_c('li',{on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/tutorials"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'Tutorials' ||
                  _vm.$route.name == 'TutorialDetails',
              }},[_c('span',[_c('i',{staticClass:"fas fa-film"}),_vm._v(" Tutorials")])])])],1):_vm._e(),(!_vm.$store.getters.isNonTeachingStaff)?_c('li',{on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/my-calendar"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'MyCalendar' }},[_c('span',[_c('i',{staticClass:"fas fa-calendar-alt"}),_vm._v("Timetable")])])])],1):_vm._e(),(!_vm.$store.getters.isNonTeachingStaff)?_c('li',{class:_vm.$store.getters.canExamCRUD ? 'show_list' : 'hide_list',on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/examinations"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'ExaminationSets' ||
                  _vm.$route.name == 'Examinations' ||
                  _vm.$route.name == 'AddExamination' ||
                  _vm.$route.name == 'EditExamination' ||
                  _vm.$route.name == 'ExamDetails' ||
                  _vm.$route.name == 'AnswerSheet' ||
                  _vm.$route.name == 'ExamStatistics' ||
                  _vm.$route.name == 'AnswerSheet' ||
                  _vm.$route.name == 'ExamSubmissions',
              }},[_c('span',[_c('i',{staticClass:"fas fa-copy"}),_vm._v(" Examinations")])])])],1):_vm._e(),_c('li',{on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/cafeteria"}},[_c('div',{staticClass:"side-link",class:{active: _vm.$route.name == 'Cafeteria' ||
                  _vm.$route.name == 'GenerateCoupon' ||
                  _vm.$route.name == 'PreviousCoupons'
              }},[_c('span',[_c('i',{staticClass:"el-icon-food"}),_vm._v(" Cafeteria")])])])],1)])]),_c('div',{},[_c('ul',{},[_c('li',{on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/settings"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'Settings' }},[_c('span',[_c('i',{staticClass:"fas fa-cog"}),_vm._v(" Settings")])])])],1),_c('li',{on:{"click":_vm.onLogoutIntent}},[_vm._m(0)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-link"},[_c('span',[_c('i',{staticClass:"fas fa-sign-out-alt"}),_vm._v(" Logout")])])
}]

export { render, staticRenderFns }