<template>
  <div class="mx-0 row w-100">
    <div class="col-md-6 pr-md-0">
      <div v-loading="isLoading"
        class="bg-white w-100 h-100">
        <div style="  padding-left: 15px;padding-top: 25px;padding-bottom: 40px;">
          <div class="card-title text-left">
            Live Classes % Attendance in last 7 days
          </div>
          <div v-if="liveClassStats"
            class="card-sub-title text-left">
            {{ liveClassStats.startDate }} - {{ liveClassStats.endDate }}
          </div>
          <div class="card-sub-title"
            v-else> ... </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div id="chart"
            class="w-100">
            <div class="w-100 h-100">
              <VueApexCharts width="100%"
                style="height: 100%; pointer-events:none !important;"
                type="area"
                :key="chartKey"
                :options="options"
                :series="series" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 pr-md-0">
      <div v-loading="isLoading"
        class="bg-white w-100 h-100"
        style="; padding: 25px 15px 25px 15px">
        <div>
          <div style="text-align: left">
            <h6 style="font-weight: 600; color: var(--el-app-primary)">
              Class Attendance Stats in last 7 days
            </h6>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center"
          style=" padding: 20px 0px; ">
          <div class="d-flex justify-content-center align-items-center">
            <el-progress type="dashboard"
              :percentage="percentageAttended" />
          </div>
          <div v-if="liveClassStats">
            <span class="stats">{{ liveClassStats.totalLiveClassesAttended }}/{{
              liveClassStats.totalLiveClasses
            }}</span><br>
            <span><small>Attended</small></span>
          </div>
          <div class="card-sub-title"
            v-else> ... </div>
        </div>
        <hr>
        <div v-if="liveClassStats"
          class="footer">
          <div style="text-align: left">
            <span style="color: grey"><small>Total Scheduled</small></span><br>
            <span style="color: grey"><small>{{ liveClassStats.totalLiveClasses }}</small></span>
          </div>
          <div style="text-align: left">
            <span style="color: grey"><small>Total Missed</small></span><br>
            <span style="color: grey"><small>{{ liveClassStats.totalLiveClasses -
              liveClassStats.totalLiveClassesAttended }}</small></span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      isLoading: false,
      liveClassStats: null,
      chartKey: 0,
      options: {
        dataLabels: { enabled: false },
        tooltip: { enabled: false },
        zoom: {
          enabled: false,
        },
        chart: {
          toolbar: { show: false },
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          labels: { show: false },
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
        },
      },
      series: [
        {
          name: "series-1",
          // data: [30, 20, 22, 11, 0, 4, 5, 20, 21, 76, 88, 54, 0, 0, 3, 5, 43],
          data: [],
        },
      ]
    }
  },

  computed: {
    percentageAttended() {
      if (!this.liveClassStats?.totalLiveClasses) return 0;
      return +((this.liveClassStats.totalLiveClassesAttended / this.liveClassStats?.totalLiveClasses) * 100).toFixed(1);
    }
  },

  methods: {
    async getLiveClassStats() {
      try {
        this.isLoading = true;
        let request = await this.$http.get("staff/fetch-live-lecture-attendance-stats-for-lecturer");
        if (
          request.data.success &&
          request.data.message == "Live classes attendance stats fetched successfully"
        ) {
          this.liveClassStats = request.data.liveClassStats;
          this.series[0].data = request.data.liveClassStats?.percentageAttendanceStats || [];
          this.chartKey++;
        }
      } finally {
        this.isLoading = false;
      }
    }
  },

  mounted() {
    this.getLiveClassStats()
  }
}

</script>
  
<style scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stats {
  font-weight: 600;
  font-size: 25px;
  color: var(--el-app-primary);
}

.card-title {
  font-weight: 600;
  font-size: 1em;
  color: var(--el-app-primary)
}

.card-sub-title {
  font-weight: 500;
  font-size: .85em;
  color: #9e9e9eb6;
}

.bg-white {
  background-color: white;
}
</style>