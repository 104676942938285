<template>
  <div class="container-fluid">
    <br /><br />
    <!-- {{ this.$route.params.courseWorkId }}<br/><br/>
    {{ this.$route.params.data }} -->
    <div
      v-if="loading"
      style="
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <div>
        <ScaleOut
          class="mt-3"
          :background="'#164B70'"
        /><br />
        <span style="font-weight: 500">Loading Course Work Details...</span>
      </div>
    </div>

    <div v-else>
      <div id="the-tab-section">
        <div style="display: flex; justify-content: center">
          <div id="the-tab">
            <el-tabs
              class="mx-auto"
              :stretch="true"
              v-model="activeName"
              @tab-click="onTabClick"
            >
              <el-tab-pane name="questionPaper">
                <span slot="label"><i class="el-icon-paperclip pr-2"></i> Question Paper</span>
              </el-tab-pane>
              <el-tab-pane name="answerSheet">
                <span slot="label"><i class="el-icon-document pr-2"></i> Answer Sheet</span>
              </el-tab-pane>
              <el-tab-pane name="submissions">
                <span slot="label"><i class="el-icon-user"></i> Submissions</span>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <div
        v-if="activeName == 'questionPaper'"
        class="row px-4"
      >
        <div class="col-12 text-left pb-3 mt-5">
          <BreadCrumbNavigation
            v-if="course_work"
            :pageTitles="['Course Work', 'Question Paper']"
          />
        </div>

        <DocumentContent
          :type="'questionPaper'"
          :course_work="course_work"
        />

        <div
          v-loading="loading"
          style="height: 50vh"
        ></div>
      </div>

      <div
        v-else-if="activeName == 'answerSheet'"
        class="row"
      >
        <div class="col-12 text-left pb-3 mt-5">
          <BreadCrumbNavigation
            v-if="course_work"
            :pageTitles="['Course Work', 'Answer sheet']"
          />
        </div>

        <DocumentContent
          :type="'answerSheet'"
          :course_work="course_work"
        />

        <div
          v-loading="loading"
          style="height: 50vh"
        ></div>
      </div>

      <div
        v-else-if="activeName == 'submissions'"
        class="row"
      >
        <div class="col-12 text-left pb-3 mt-5">
          <BreadCrumbNavigation
            v-if="course_work"
            :pageTitles="['Course Work', 'Student Submissions']"
          />
        </div>

        <article style="width: 100%">
          <section style="padding-top: 10px">
            <div class="container">
              <div class="row w-100">
                <div class="col-12 text-left">
                  <div style="
                      font-size: 20px;
                      font-weight: 400;
                      color: var(--el-app-primary);
                      margin-bottom: 15px;
                    ">
                    <strong>"{{ course_work.course_unit_code }}
                      {{ course_work.title }}" Student Submissions</strong>
                  </div>
                </div>
              </div>
              <!-- {{ batches }} -->
              <div class="search_add_section">
                <div class="">
                  <input
                    type="text"
                    v-model.trim="filters[0].value"
                    placeholder="Search by Student Name"
                    class="search_by_input"
                    spellcheck="off"
                  />
                </div>
                <div style="display: flex; align-items: center;">
                  <el-dropdown>
                    <el-button type="primary">
                      Download / Intake <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu
                      trigger="click"
                      class="project-dropdown"
                      slot="dropdown"
                    >
                      <el-dropdown-item
                        v-for="batch in batches"
                        :key="batch.course_intake_batch_course_work_id"
                        @click.native="downloadStudentSubmissions(batch.course_intake_batch_course_work_id)"
                        style="display: flex; align-items:center; justify-content: space-between;"
                      >
                        {{`${batch.course_intake_batch.course.course_code} ${batch.course_intake_batch.year} 
                        ${toMonthName(batch.course_intake_batch.month)}( ${batch.course_intake_batch.cohort})`}}
                        <i class="fas fa-download"></i>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div style="margin-left: 20px;">
                    <el-button
                      type="primary"
                      @click="downloadStudentSubmissions()"
                    >Download All <i class="fas fa-download"></i></el-button>
                  </div>
                </div>
              </div>
              <hr />
              <div
                v-loading="submiting"
                style="width: 100%"
              >
                <data-tables
                  :data="student_doing_course_work"
                  :table-props="tableProps"
                  :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                  type="expand"
                  :filters="filters"
                >
                  <div slot="empty">
                    <ScaleOut
                      v-if="loading && !loadingError"
                      class="mt-3"
                      :background="'#164B70'"
                    />
                    <div
                      v-else-if="!loading && loadingError"
                      style="cursor: pointer"
                    >
                      Unable to Load Course Work Now. Please click here to retry
                    </div>
                    <div v-if="!loading && !loadingError">
                      No Student Submissions
                    </div>
                  </div>

                  <el-table-column
                    label="#"
                    :show-overflow-tooltip="true"
                    label-class-name="text-center"
                    class-name="text-center"
                    width="80px"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.$index + 1 }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Student"
                    :show-overflow-tooltip="true"
                    label-class-name="text-left"
                    width="auto"
                  >
                    <template slot-scope="scope">
                      <div style="display: flex; align-items: center">
                        <div style="width: 40px; height: 40px; border-radius: 50%">
                          <div
                            v-if="scope.row.student.profile_picture"
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              background-color: var(--vu-red);
                              color: white;
                              width: 40px;
                              height: 40px;
                              object-fit: cover;
                              border-radius: 100%;
                            "
                          >
                            <img
                              :src="scope.row.student.profile_picture"
                              class="img-fluid"
                              style="
                                width: 40px;
                                height: 40px;
                                object-fit: cover;
                                border-radius: 100%;
                              "
                            />
                          </div>
                          <div
                            v-if="scope.row.student.profile_picture == null"
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              background-color: var(--vu-red);
                              color: white;
                              width: 40px;
                              height: 40px;
                              object-fit: cover;
                              border-radius: 100%;
                            "
                          >
                            <strong>{{
                              getInitials(
                                `${scope.row.student.first_name} ${scope.row.student.last_name}`
                              )
                            }}</strong>
                          </div>
                        </div>
                        <div style="margin-left: 10px">
                          <span>{{ scope.row.student.first_name }}
                            {{ scope.row.student.last_name }}</span><br />
                          <span><strong style="font-weight: 600">Reg No.</strong>
                            {{ scope.row.student.registration_no }}</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Intake"
                    :show-overflow-tooltip="true"
                    label-class-name="text-center"
                    class-name="text-center"
                  >
                    <template slot-scope="scope">
                      <strong>
                        Intakes
                        {{getIntakeInfo(scope.row.course_intake_batch_course_work_id)}}
                      </strong>
                    </template>
                  </el-table-column>

                  <el-table-column label="Submission Status">
                    <template slot-scope="scope">
                      <div>
                        <div>
                          <strong style="font-weight: 600;">Started:</strong>
                          {{ formattedDate(scope.row.started_at) }}
                        </div>
                        <div v-if="scope.row.submitted_at">
                          <strong style="font-weight: 600;">Submitted:</strong>
                          {{ formattedDate(scope.row.submitted_at) }}

                          <div>
                            <strong style="font-weight: 600;">Valid Booklet Serial No:</strong> {{`CW${scope.row.is_online ? 'OCW' : ''}-${scope.row.raw_student_course_work_submission_id}`}}
                          </div>
                        </div>

                        <div v-else>
                          <strong style="font-weight: 600;">Submitted:</strong> Not Yet
                        </div>

                        <div
                          v-if="isGrantedSpecialSubmission(scope.row)"
                          class="text-truncate"
                        >
                          <strong style="font-weight: 600;">Special Submit:</strong>
                          {{ scope.row.special_submission_time != null ? formattedDate(scope.row.special_submission_time) : `N/L` }}

                        </div>

                        <div
                          v-if="isGrantedSpecialSubmission(scope.row)"
                          class="text-truncate"
                        >
                          <strong style="font-weight: 600;">Has Resubmitted:</strong> {{ scope.row.has_resubmitted ? 'Yes' : 'No'}}
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="Special Submission"
                    :show-overflow-tooltip="true"
                    label-class-name="text-center"
                    class-name="text-center"
                  >
                    <template slot-scope="scope">
                      <div>
                        <el-tooltip content="Grant Permission">
                          <el-button
                            :type="isGrantedSpecialSubmission(scope.row) ? 'success':'primary'"
                            @click="showGrantTimeDialog(scope.row.student_id)"
                            icon="fas fa-lock"
                            circle
                          ></el-button>
                        </el-tooltip>
                        <el-tooltip content="Download Submission">
                          <el-button
                            type="success"
                            @click="downloadOneStudentCourseWorkSubmission(scope.row.student_course_work_submission_id)"
                            icon="fas fa-download"
                            circle
                          ></el-button>
                        </el-tooltip>
                      </div>
                    </template>
                  </el-table-column>

                </data-tables>
              </div>
            </div>
          </section>
        </article>
      </div>
    </div>

    <section>
      <div class="container">
        <div class="grant_dialogs">
          <el-dialog
            title="Grant Special Course Work Submission"
            :visible.sync="show_dialog"
            :destroy-on-close="true"
            :before-close="handleClose"
          >
            <div v-loading="submiting">
              <el-form
                :model="grantForm"
                :rules="rules"
                ref="grantForm"
                class="demo-grantForm2"
              >
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item prop="individual_granted_closing_time">
                      <el-date-picker
                        style="width: 100%;"
                        v-model="grantForm.individual_granted_closing_time"
                        :format="'dd MMMM yyyy hh:mm A'"
                        type="datetime"
                        placeholder="Select date and time"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </div>
                </div>
                <el-form-item>
                  <el-button
                    type="primary"
                    :disabled="!this.grantForm.individual_granted_closing_time"
                    @click="grantTimeChanges"
                    style="width: 100%; background-color: var(--el-app-primary) !important"
                  >Save Changes</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center mt-5">
                <div class="text-center">
                  <small></small>
                </div>
                <div class="text-center">
                  <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Tabs, TabPane } from "element-ui";
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbNavigation from "../../components/bread-crumb-component.vue";
import DocumentContent from "../../components/courseworks/document-content.vue";

import { format, parseISO } from "date-fns";
import getMonthName from "../../helpers/getMonthName";

export default {
  components: {
    DocumentContent,
    ScaleOut,
    "el-tabs": Tabs,
    "el-tab-pane": TabPane,
    BreadCrumbNavigation,
  },

  data() {
    return {
      loading: false,
      submiting: false,
      activeName: "questionPaper",
      isLoading: true,
      show_dialog: false,

      course_work: {},
      // course_work: this.$route.params.course_work,
      courseWorkId: this.$route.params.courseWorkId, //this is the course_work id from the browser
      student_id: "",

      batches: [],
      student_doing_course_work: [],
      question_fileExtension: "",
      answerSheet_fileExtension: "",

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      selectedBatchId: "",

      filters: [
        {
          prop: "",
          value: "",
          filterFn: (row, filterItem) => {
            return `${row.student.first_name} ${row.student.last_name}`
              .toLowerCase()
              .includes(filterItem.value.toLowerCase().trim());
          },
        },
      ],

      grantForm: {
        individual_granted_closing_time: "",
      },

      rules: {
        title: [
          {
            required: true,
            message: "Please grant closing time",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    downloadProgress() {
      return Math.round(this.progress * 100);
    },

    isGrantedSpecialSubmission() {
      return (student) => student.special_submission_time;
    },

    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },

    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getCourseWork();
    // If there's No course_work, e.g page has reloaded, go back to previous page
    // if (!this.course_work) {
    //     this.$router.back();
    // }
  },

  methods: {
    getIntakeInfo(course_intake_batch_course_work_id) {
      const intake = this.course_work.course_work_intakes.find(
        (item) =>
          item.course_intake_batch_course_work_id ==
          course_intake_batch_course_work_id
      );

      return `${intake.course_intake_batch.course.course_code} ${
        intake.course_intake_batch.year
      } ${this.toMonthName(intake.course_intake_batch.month).toUpperCase()} ${
        intake.course_intake_batch.cohort
      } (${intake.course_unit.course_unit_code})`;
    },

    toMonthName(monthNum) {
      return getMonthName(monthNum, true);
    },

    handleClose(done) {
      this.grantForm.individual_granted_closing_time = "";
      done();
    },

    async getCourseWork() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `course-work/fetch-one/${this.courseWorkId}?include_submissions=true`
        );
        if (
          request.data.success &&
          request.data.message == "COURSE_WORK FETCHED SUCCESSFULLY"
        ) {
          this.course_work = request.data.course_work;
          this.student_doing_course_work =
            request.data.course_work.student_course_work_submissions;
          this.batches = this.course_work.course_work_intakes;
        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load course_work",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    showGrantTimeDialog(student_id) {
      this.student_id = student_id;
      this.show_dialog = true;
    },

    async grantTimeChanges() {
      if (!this.grantForm.individual_granted_closing_time) return;

      try {
        this.submiting = true;
        let request = await this.$http.post(
          `course-work/grant-student-additional-time`,
          {
            special_submission_time:
              this.grantForm.individual_granted_closing_time,
            course_work_id: this.courseWorkId,
            student_id: this.student_id,
          }
        );
        if (
          request.data.success &&
          request.data.message == "STUDENT GRANTED SPECIAL ACCESS TIME"
        ) {
          this.grantForm.individual_granted_closing_time = "";
          this.show_dialog = false;
          this.showSuccessMessage(
            "Success",
            "Student Special Submission granted successfully."
          );
          return this.getCourseWork();
        } else if (
          request.data.message ==
          "COURSE_WORK IS AVAILABLE AND STUDENT HAS NOT SUBMITTED YET"
        ) {
          this.grantForm.individual_granted_closing_time = "";
          this.show_dialog = false;
          return this.showWarningMessage(
            "Course work available",
            "Course work is available and student has not submitted yet.."
          );
        } else if (
          request.data.messsage == "STUDENT HAS NOT STARTED THE COURSE_WORK"
        ) {
          this.show_dialog = false;
          this.showWarningMessage(
            "Not Allowed",
            "The Student has not yet started this Course Work."
          );
        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
        if (error.response && error.response.status == 400) {
          if (error.response.data.message == "REQUIRED PARAMS MISSING") {
            this.$rollbar.warning(
              "ADMIN FRONT END: Required Params were missing"
            );
            return this.showFailedMessage(
              "Incomplete Info",
              "Incomplete Data submitted. Please try again"
            );
          } else if (error.response.data.message == "INVALID DATE SUPPLIED") {
            return this.showWarningMessage(
              "Invalid Date",
              "The Date you entered is invalid. Please review it and try again"
            );
          } else if (
            error.response.data.message ==
            "SPECIAL SUBMISSION TIME CAN NOT BE IN THE PAST"
          ) {
            return this.showWarningMessage(
              "Unaccepted Date",
              "The Date you entered can not be in the past. Please review it and try again"
            );
          } else {
            this.$rollbar.warning(
              "ADMIN FRONT END: Unexpected 400 API response while getting server response",
              error.response.data,
              error.response
            );

            return this.showWarningMessage(
              "Operation Failed",
              "Unable to complete operation now. Please try again"
            );
          }
          // return Promise(error);
        }

        if (error.response.data == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to add Additional Time",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submiting = false;
      }
    },

    onPDfError() {
      this.showFailedMessage(
        "loading Failed",
        "Unable to load the selected Document, please try again"
      );
      // this.$router.back();
    },

    onTabClick() {},

    downloadQuestionPaper(path_key) {
      const ext = path_key.substring(path_key.lastIndexOf(".") + 1);
      this.submiting = true;
      this.$http
        .get(`course-work/download-question-paper/${path_key}`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Question-paper-${new Date().toLocaleDateString()}.${ext}`
          );
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.submiting = false;
        });
    },

    async downloadCourseWorkAnswers(selectedBId) {
      this.selectedBatchId = selectedBId;
      try {
        this.submiting = true;
        let request = await this.$http.get(
          `course-work/download-students-submissions/${selectedBId}/${this.courseWorkId}`
        );

        if (request.data.success && request.data.message == "FILES_FOUND") {
          let url = request.data.link;
          let label = request.data.label;
          this.downloadForBatch({ url, label });
        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
        this.loadingError = true;
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message == "REQUIRED_INFOR_IS_MISSING") {
            this.showWarningMessage("Required", "Required info is missing");
          } else if (
            error.response.data.message == "COURSE_INTAKE_BATCH_ID_IS_REQUIRED"
          ) {
            this.showWarningMessage("Required", "Intake is required");
          } else if (error.response.data.message == "BATCH_NOT_FOUND") {
            this.showWarningMessage("Not found", "Intake is not found");
          } else if (error.response.data.message == "NO_FILES_FOUND") {
            this.showWarningMessage("Not found", "There are no files found");
          } else if (
            error.response.data.message == "PROCESSING_ATTENDENCE_LIST_FAILED"
          ) {
            this.showWarningMessage(
              "Processing Failed",
              "Processing attendance list has failed"
            );
          } else if (
            error.response.data.message ==
            "INFOR_FOR_PROCESSING_ATTENDENCE_LIST_IS_REQUIRED"
          ) {
            this.showWarningMessage(
              "Required",
              "Info for Processing attendance list is required"
            );
          } else {
            this.showFailedMessage(
              "Unable to Download Answers",
              "An unexpected Error occurred, please try again"
            );
          }
        } else {
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to Download Answers",
            "An unexpected Error occurred, please try again"
          );
        }
      } finally {
        this.submiting = false;
      }
    },

    async downloadForBatch({ url, label }) {
      try {
        this.submiting = true;
        let request = await this.$http.get(url, { responseType: "blob" });
        const urll = URL.createObjectURL(new Blob([request.data]));
        const link = document.createElement("a");
        link.href = urll;
        link.setAttribute("download", `${label}`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        this.loadingError = true;

        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to Download Answers",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submiting = false;
      }
    },

    async downloadAll() {
      try {
        this.submiting = true;
        let request = await this.$http.get(
          `course-work/download-all-students-submissions/${this.courseWorkId}`
        );
        if (request.data.success && request.data.message == "FILES_FOUND") {
          let url = request.data.link;
          let label = request.data.label;
          this.downloadAllIntakeAnswers({ url, label });
        } else {
          throw "ERROR OCCURED";
        }
      } catch (error) {
        this.loadingError = true;

        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        this.showFailedMessage(
          "Unable to Download Answers",
          "An unexpected Error occurred, please try again"
        );

        if (error.response) {
          if (error.response.data.message == "REQUIRED_INFOR_IS_MISSING") {
            this.showWarningMessage("Required", "Required info is missing");
          } else if (error.response.data.message == "NO_FILES_FOUND") {
            this.showWarningMessage("Not found", "There are no files found");
          } else if (
            error.response.data.message == "PROCESSING_ATTENDENCE_LIST_FAILED"
          ) {
            this.showWarningMessage(
              "Processing Failed",
              "Processing attendance list has failed"
            );
          } else if (
            error.response.data.message ==
            "INFOR_FOR_PROCESSING_ATTENDENCE_LIST_IS_REQUIRED"
          ) {
            this.showWarningMessage(
              "Required",
              "Info for Processing attendance list is required"
            );
          } else {
            this.loadingError = true;
            this.showFailedMessage(
              "Unable to Download Answers",
              "An unexpected Error occurred, please try again"
            );
          }
        } else {
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to Download Answers",
            "An unexpected Error occurred, please try again"
          );
        }
      } finally {
        this.submiting = false;
      }
    },

    async downloadAllIntakeAnswers({ url, label }) {
      try {
        this.submiting = true;
        let request = await this.$http.get(url, { responseType: "blob" });
        const urll = URL.createObjectURL(new Blob([request.data]));
        const link = document.createElement("a");
        link.href = urll;
        link.setAttribute("download", `${label}`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        this.loadingError = true;

        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to Download Answers",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.submiting = false;
      }
    },

    async downloadStudentSubmissions(course_intake_batch_course_work_id) {
      try {
        this.submiting = true;
        let queryParam = "";
        if (course_intake_batch_course_work_id) {
          queryParam = `?course_intake_batch_course_work_id=${course_intake_batch_course_work_id}`;
        }
        let request = await this.$http.get(
          `course-work/download-students-submissions/${this.courseWorkId}${queryParam}`
        );

        if (
          request.data.success &&
          request.data.message == "STUDENT SUBMISSIONS ZIP GENERATED"
        ) {
          const link = document.createElement("a");
          link.href = request.data.link;
          link.click();
        } else if (request.data.message == "NO STUDENTS HAVE SUBMITTED YET") {
          this.showWarningMessage(
            "No Submissions",
            "No Submission activity for the chosen Course Work"
          );
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        // console.log(error);
        this.loadingError = true;
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (
            error.response.data.message == "COURSE_INTAKE_BATCH_ID_IS_REQUIRED"
          ) {
            this.showWarningMessage("Required", "Intake is required");
          } else if (error.response.data.message == "BATCH_NOT_FOUND") {
            this.showWarningMessage("Not found", "Intake is not found");
          } else if (error.response.data.message == "NO_FILES_FOUND") {
            this.showWarningMessage("Not found", "There are no files found");
          } else if (
            error.response.data.message == "PROCESSING_ATTENDENCE_LIST_FAILED"
          ) {
            this.showWarningMessage(
              "Processing Failed",
              "Processing attendance list has failed"
            );
          } else if (
            error.response.data.message ==
            "INFOR_FOR_PROCESSING_ATTENDENCE_LIST_IS_REQUIRED"
          ) {
            this.showWarningMessage(
              "Required",
              "Info for Processing attendance list is required"
            );
          } else {
            this.showFailedMessage(
              "Unable to Download Answers",
              "An unexpected Error occurred, please try again"
            );
          }
        } else {
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to Download Answers",
            "An unexpected Error occurred, please try again"
          );
        }
      } finally {
        this.submiting = false;
      }
    },

    async downloadOneStudentCourseWorkSubmission(studentCourseworkSubmissionId) {
      let request = await this.httpRequest({
        method: "GET",
        url: `course-work/download-one-student-submission/${studentCourseworkSubmissionId}`,
        loadingPropertyName: "submiting",
        errorLoadingPropertyName: null,
        showSuccessMessage: true,
      });
      if (
        request &&
        request.success &&
        request.message == "Student Course work submission retrieved successfully"
      ) {
          const link = document.createElement("a");
          link.href = request.path_url;
          link.click();
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.direction_arrows {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgb(214, 226, 238);
  color: var(--el-app-primary);
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direction_arrows i {
  font-size: 15px;
}

#the-tab-section {
  width: 80%;
  position: fixed;
  background-color: #f6f6f6;
  z-index: 100;
  top: 60px;
  padding-top: 30px;
}

#the-tab {
  width: 50%;
}

.TabpaddingTop {
  margin: 130px 27px 0px 27px;
  width: 100%;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 10px 0px 10px;
  }

  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  #the-tab-section {
    width: 100%;
  }

  #the-tab {
    width: 100%;
  }

  .TabpaddingTop {
    margin: 130px 5px 0px 5px;
  }
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.grant_dialogs .el-dialog {
  width: 30%;
  text-align: left;
}

.search_by_input {
  width: 400px !important;
}

.grant_dialogs .el-select .el-input__inner {
  cursor: pointer;
  height: 36px !important;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}
.grant_dialogs .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

.el-table th > .cell {
  color: black !important;
  font-weight: 500;
}
.el-table .cell {
  font-size: 0.9em;
  line-height: 23px;
  font-weight: 300;
}

.grant_dialogs .el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.grant_dialogs .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.grant_dialogs .el-upload-dragger {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 366px !important;
  height: 150px !important;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.grant_dialogs .el-icon-upload:before {
  font-size: 50px;
}

.project-dropdown {
  height: 300px;
  overflow: auto;
}
.project-dropdown::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
.project-dropdown::-webkit-scrollbar-track {
  /* //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px;
  background-color: #f5f5f5;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .grant_dialogs .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .grant_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .grant_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .grant_dialogs .el-dialog {
    width: 90%;
  }
}
</style>